// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cfs-tsx": () => import("./../../src/pages/cfs.tsx" /* webpackChunkName: "component---src-pages-cfs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-timetable-tsx": () => import("./../../src/pages/timetable.tsx" /* webpackChunkName: "component---src-pages-timetable-tsx" */)
}

